import React, {useEffect, useRef, useState} from 'react';
import Amplify, {Auth} from 'aws-amplify';
import {AuthSetup} from '../mocks/authorisation.mock';
import styles from './Login.module.scss';
import Cookies from 'universal-cookie';
import {Spinner} from '@acp/lib-ui-react-common';
import {getAuthorisationSignedCookies, getIdpOktaConfig} from '../service/api';
import {handleRedirect} from '../utils/helper';

interface ILogin extends AuthSetup {
    hasExpiredSession?: boolean;
    hasLoginError?: boolean;
    hasSetupError?: boolean;
}

export const Login = (props: ILogin) => {
    const browserCookies = new Cookies();
    const [state, setState] = useState({
        errors: {
            validation: {
                email: {
                    message: '',
                },
            },
            submission: {
                response: '',
            },
        },
        btnClicked: false,
        checkingIdp: false,
    });
    const passwordInputRef = useRef(null);

    useEffect(() => {
        const domain =
            props.psDomain && props.psDomain.indexOf('icm') === 0
                ? `prod-${props.psDomain.replace(/\./g, '-')}.auth.us-east-1.amazoncognito.com`
                : `${props.psDomain.replace(/\./g, '-')}.auth.us-east-1.amazoncognito.com`;
        Amplify.configure({
            Auth: {
                region: 'us-east-1',
                userPoolId: props.userPoolId,
                userPoolWebClientId: props.webAppClientId,
                mandatorySignIn: false,
                oauth: {
                    domain: domain,
                    scope: props.oauth.scope,
                    redirectSignIn: props.oauth.redirectSignIn,
                    redirectSignOut: props.oauth.redirectSignOut,
                    responseType: 'token',
                },
            },
        });
    }, [
        props.oauth.redirectSignIn,
        props.oauth.redirectSignOut,
        props.webAppClientId,
        props.userPoolId,
        props.oauth.scope,
        props.psDomain,
        props.oktaHost,
    ]);

    const handleOktaLinkClick = (oktaHost: string) => {
        window.location.href = `https://${oktaHost}`;
    };

    const generateFederationButtons = (identityProviders: any) => {
        return identityProviders !== undefined ? (
            identityProviders.Providers.map((idp: any, i: any) => (
                <div className={i !== 0 ? styles.providerWrapper : ''} key={i}>
                    <button
                        type="button"
                        key={i}
                        className={`${styles.providerBtn} btn btn-primary mr-2`}
                        onClick={() => {
                            if (idp.ProviderName === 'Okta') {
                                handleOktaLinkClick(props.oktaHost);
                            } else {
                                Auth.federatedSignIn({provider: idp.ProviderName});
                            }
                        }}
                    >
                        Sign in with {idp.ProviderName}
                    </button>
                </div>
            ))
        ) : (
            <div>No Identity Provider available</div>
        );
    };

    const handleSubmit = (event: any) => {
        setState({
            btnClicked: true,
            errors: {
                validation: {
                    email: {
                        message: '',
                    },
                },
                submission: {
                    response: '',
                },
            },
            checkingIdp: false,
        });
        event.preventDefault();
        const username = event.target[0].value;
        const password = event.target[1].value;

        Auth.signIn(username, password)
            .then(user => {
                Auth.currentSession().then(async res => {
                    let accessToken: any = res.getAccessToken();
                    browserCookies.set('accessToken', accessToken, {domain: '.accenture.com', path: '/'});
                    await getAuthorisationSignedCookies(accessToken.jwtToken);
                    handleRedirect(props.psDomain);
                });
            })
            .catch(err => {
                setState({
                    btnClicked: false,
                    errors: {
                        validation: {
                            email: {
                                message: '',
                            },
                        },
                        submission: {
                            response: err.message,
                        },
                    },
                    checkingIdp: false,
                });
            });
    };

    const validateEmail = (entry: any) => {
        const validEmail = entry.search(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
        return validEmail < 0 ? 'invalid email address' : '';
    };

    const handleOktaRedirect = (oktaHost: string, email: string) => {
        window.location.href = `https://${oktaHost}?username=${email}`;
    };

    const resetInput = () => {
        setState({
            btnClicked: false,
            errors: {
                validation: {
                    email: {
                        message: '',
                    },
                },
                submission: {
                    response: '',
                },
            },
            checkingIdp: false,
        });
    };

    const cognitoLogin = () => {
        setState({
            btnClicked: false,
            errors: {
                validation: {
                    email: {
                        message: '',
                    },
                },
                submission: {
                    response: '',
                },
            },
            checkingIdp: false,
        });
        const passwordEle: any = passwordInputRef.current;
        passwordEle && passwordEle.focus();
    };

    const checkIdp = async (e: any) => {
        const value = e.target.value;
        if (value !== '') {
            setState({
                btnClicked: false,
                errors: {
                    validation: {
                        email: {
                            message: '',
                        },
                    },
                    submission: {
                        response: '',
                    },
                },
                checkingIdp: true,
            });
            const validateEmailMessage = validateEmail(value);
            if (validateEmailMessage.length <= 0) {
                e.preventDefault();
                const idpDomain = e.target.value.split('@').pop();
                const accentureIdps = ['accenture.com', 'ds.dev.accenture.com', 'avanade.com', 'accenturefederal.com'];
                if (accentureIdps.indexOf(idpDomain) > -1) {
                    const accentureIdp: any = 'accenture.com';
                    Auth.federatedSignIn({provider: accentureIdp});
                } else {
                    try {
                        const isOkta = await getIdpOktaConfig(idpDomain);
                        if (isOkta.okta) {
                            handleOktaRedirect(props.oktaHost, value);
                        } else {
                            cognitoLogin();
                        }
                    } catch (e) {
                        // IF ERROR CALLING IDP CHECK API THEN ASSUME FALSE
                        // AND SHOW COGNITO LOGIN
                        cognitoLogin();
                    }
                }
            } else {
                setState({
                    btnClicked: false,
                    errors: {
                        validation: {
                            email: {
                                message: validateEmailMessage,
                            },
                        },
                        submission: {
                            response: '',
                        },
                    },
                    checkingIdp: false,
                });
            }
        }
    };

    return (
        <React.Fragment>
            <div className={styles.wrapper}>
                <div className={styles.loginForm}>
                    <h3 className={styles.center}>ICM - Intelligent Cloud Management</h3>
                    <hr className={styles.hrCustom} />
                    {props.idps &&
                        props.idps.Providers &&
                        props.idps.Providers.length > 0 &&
                        generateFederationButtons(props.idps)}
                    <hr className={styles.hrCustom} />
                    {props.hasSetupError && (
                        <span className={styles.errorText}>
                            There has been a problem with setup, please contact support.
                        </span>
                    )}
                    {props.hasExpiredSession && (
                        <span className={styles.errorText}>
                            Your session has expired! Please login again.
                            <br />
                        </span>
                    )}
                    {props.hasLoginError && (
                        <span className={styles.errorText}>
                            There was an error validating your login, please try again.
                            <br /> If this continues, please contact support.
                        </span>
                    )}
                    {state.btnClicked && (
                        <div className={styles.spinnerLocation}>
                            <Spinner>
                                <div>Signing you into ACP...</div>
                            </Spinner>
                        </div>
                    )}
                    {!state.btnClicked && !props.hasSetupError && (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    autoFocus
                                    onBlur={checkIdp}
                                    id="email"
                                    name="email"
                                    onChange={resetInput}
                                    className="form-control"
                                    autoComplete="email"
                                />
                                {state.errors.validation.email.message && (
                                    <span className={styles.errorText}>{state.errors.validation.email.message}</span>
                                )}
                            </div>
                            {!state.checkingIdp && (
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            id="password"
                                            ref={passwordInputRef}
                                            name="password"
                                            type="password"
                                            className="form-control"
                                            autoComplete="new-password"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className={`${styles.signInButton} btn btn-primary mr-2`}>
                                            Sign in
                                        </button>
                                    </div>
                                </div>
                            )}
                            {state.checkingIdp && (
                                <Spinner>
                                    <div>Checking domain...</div>
                                </Spinner>
                            )}
                            {state.errors.submission.response && (
                                <span className={styles.errorText}>{state.errors.submission.response}</span>
                            )}
                        </form>
                    )}
                </div>
            </div>
            <footer className={styles.footer}>
                <a className={styles.footerLink} href="https://icm.accenture.com/public/terms-of-use/">
                    Terms of use
                </a>
                <br />
                <small>©2020 Accenture All Rights Reserved</small>
            </footer>
        </React.Fragment>
    );
};
